<template>
  <div class="lcf-notice-list">
    <NavBar
      title="平台公告"
      left-arrow
      fixed
      @click-left="onClickLeft"
      placeholder
    />
    <PullRefresh v-model="refreshing" @refresh="onRefresh">
      <List
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        @load="onLoad"
      >
        <ListTransition>
          <div
            class="lcf-notice-item"
            v-for="(v, k) in notices"
            :key="k"
            :data-index="k"
            :data-page-size="pageSize"
          >
            <div class="lcf-notice-item--head">
              <span class="rdm-icon"></span>
              <span class="lcf-notice-time">{{ v.addtime }}</span>
            </div>
            <div
              class="lcf-notice-item--body"
              @click="
                $router.push({ name: 'NoticeDetail', params: { id: v.id } })
              "
              v-waves
            >
              <span class="lcf-notice-title van-ellipsis">{{ v.title }}</span>
              <span class="lcf-notice-instr van-ellipsis">{{ v.instr }}</span>
            </div>
          </div>
        </ListTransition>
      </List>
    </PullRefresh>
  </div>
</template>

<script>
import { NavBar, List, PullRefresh, Notify } from 'vant'
import api from '@/api'

export default {
  components: {
    NavBar,
    List,
    PullRefresh,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      toPage: 1,
      pageSize: 10,
      totalPage: null,

      notices: [],
    }
  },
  mounted() {
    this.$once('hook:beforeDestroy', function () {
      this.notices = []
    })
  },
  methods: {
    onClickLeft() {
      this.$router.replace({name: 'Home'})
    },
    async onLoad(reloadData) {
      if (this.toPage > this.totalPage && this.totalPage != null) {
        this.finished = true
        return
      }

      let error, notices
      ;[error, notices] = await to(this.requestNoticeList(this.toPage))

      if (error) {
        this.refreshing = false
        this.loading = false
        this.error = true
        return
      }

      if (this.toPage > this.totalPage) {
        this.finished = true
        return
      }

      if (reloadData === true) {
        this.notices.length = 0
      }

      this.notices.push(...notices)
      this.toPage++
      this.loading = false
      this.refreshing = false
    },
    onRefresh() {
      let reloadData = true

      this.toPage = 1

      this.finished = false

      this.loading = true
      this.onLoad(reloadData)
    },
    async requestNoticeList(curPage = 1, pageSize = 10) {
      let notices = []
      let error, response
      ;[error, response] = await to(
        api.general.noticeList({
          code: 'notice',
          page: curPage,
          page_size: this.pageSize,
        })
      )

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (response?.data?.code === -1) {
        notices = response.data.data
        this.totalPage = response.data.total
      }
      return notices
    },
  },
  computed: {
    introduction() {
      return (source) => source.replaceAll(/\r\n/g, '')
    },
  },
}
</script>

<style lang="scss" scoped>
.lcf-notice-list {
  font-size: 16px;
  .lcf-notice-item {
    $urlList: '~@/assets/img/notice/icon_gonggao_anquan.png',
      '~@/assets/img/notice/icon_gonggao_huankuan.png',
      '~@/assets/img/notice/icon_gonggao_huodong.png',
      '~@/assets/img/notice/icon_gonggao_pingtai.png',
      '~@/assets/img/notice/icon_gonggao_qianbao.png',
      '~@/assets/img/notice/icon_gonggao_shuju.png';
    @each $var in $urlList {
      $index: index($urlList, $var);
      &:nth-child(#{$index}n) {
        .lcf-notice-item--head {
          .rdm-icon {
            background: content-box left 100% / auto 16px no-repeat url($var);
          }
        }
      }
    }
    .lcf-notice-item--head {
      display: flex;
      flex-flow: row, nowrap;
      justify-content: space-between;
      padding: 0 24px;
      height: 24px;
      background: $lcf-color-bg-gray-light;
      span {
        &.lcf-notice-time {
          text-align: right;
          line-height: 24px;
          color: $lcf-color-text-gray;
          font-size: 12px;
        }
        flex: 1 1;
      }
    }
    .lcf-notice-item--body {
      padding: 16px 24px;
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1;
      .lcf-notice-title {
        padding: 8px 0;
      }
      .lcf-notice-instr {
        padding: 0 0 8px;
        color: $lcf-color-text-gray;
        font-size: 12px;
      }
    }
  }
}
</style>
